import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw from 'twin.macro';
import {
  Aside,
  BlueButton,
  DeepDive,
  DeepDiveButton,
  DeepDiveSection,
  Div,
  Footnotes,
  HR,
  Layout,
  LottieTwoUp,
  LottieWrapper,
  NERLYNX,
  P,
  Space,
} from '~/components';
import { Frontmatter } from '~/config';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Library: DeepDive',
  orderInLib: 20,
  screenShoot: false,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      <DeepDive>
        <DeepDiveSection>
          <h2>Section One</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
            tempora quas nostrum beatae rem debitis eligendi provident maxime
            esse exercitationem, placeat quod cum sapiente odio, quos explicabo
            dolore rerum et? Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Cupiditate architecto assumenda adipisci! Nobis dicta est
            recusandae! Eaque provident ipsa sapiente, quisquam iste at magni
            explicabo quae et consequuntur maiores sequi?
          </p>

          <Aside>
            <DeepDiveButton>Learn about things</DeepDiveButton>
          </Aside>
        </DeepDiveSection>
        <DeepDiveSection isDeepDive>
          <h2>Section II</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
            tempora quas nostrum beatae rem debitis eligendi provident maxime
            esse exercitationem
          </p>

          <Aside left>
            <DeepDiveButton direction="climb-out">
              I've seen enough
            </DeepDiveButton>
          </Aside>
        </DeepDiveSection>
      </DeepDive>

      {/* Example with content next to the Aside blocks - test on mobile & desktop!  */}
      <HR yellow />
      <DeepDive>
        <DeepDiveSection>
          <h2>Section One</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
            tempora quas nostrum beatae rem debitis eligendi provident maxime
            esse exercitationem, placeat quod cum sapiente odio, quos explicabo
            dolore rerum et? Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Cupiditate architecto assumenda adipisci! Nobis dicta est
            recusandae! Eaque provident ipsa sapiente, quisquam iste at magni
            explicabo quae et consequuntur maiores sequi?
          </p>

          <LottieTwoUp
            columnOne={
              <LottieWrapper
                desktopLottiePath={'/lotties/IDFS-56.json'}
                alt={
                  'invasive disease-free survival in the HER2+ HR+ subgroup that completed therapy shows 56% reduction in risk of recurrence at 5 years'
                }
                customCss={tw`mb-2 lg:(mb-[initial])`}
              />
            }
            columnTwo={
              <Div tw="mb-8">
                <P darkBlue tw="mb-4 text-xl">
                  <strong>7.4%</strong> absolute benefit in iDFS at 5 years
                  <sup>5</sup>
                </P>
                <P cyan tw="text-xl">
                  Descriptive analysis, not prespecified or powered.
                  <sup>5</sup>
                </P>
              </Div>
            }
            customCss={tw`lg:(p-8 items-center)`}
          />

          <Space xxl />

          <Aside>
            <BlueButton to="/resources/" breakoutRight>
              View ExteNET study design
            </BlueButton>
            <DeepDiveButton>
              View iDFS in patients who completed ≥11 months of <NERLYNX />
            </DeepDiveButton>
          </Aside>

          {/* If content beside the Aside is too short, use customCss to pad down */}
          <Footnotes
            footnotes={['absolute-benefit']}
            alignment="left"
            customCss={tw`mb-10`}
          />
        </DeepDiveSection>

        <DeepDiveSection isDeepDive>
          <h2>Section One</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
            tempora quas nostrum beatae rem debitis eligendi provident maxime
            esse exercitationem, placeat quod cum sapiente odio, quos explicabo
            dolore rerum et? Lorem ipsum dolor, sit amet consectetur adipisicing
            elit. Cupiditate architecto assumenda adipisci! Nobis dicta est
            recusandae! Eaque provident ipsa sapiente, quisquam iste at magni
            explicabo quae et consequuntur maiores sequi?
          </p>

          <LottieTwoUp
            columnOne={
              <LottieWrapper
                desktopLottiePath={'/lotties/IDFS-56.json'}
                alt={
                  'invasive disease-free survival in the HER2+ HR+ subgroup that completed therapy shows 56% reduction in risk of recurrence at 5 years'
                }
                customCss={tw`mb-2 lg:(mb-[initial])`}
              />
            }
            columnTwo={
              <Div tw="mb-8">
                <P darkBlue tw="mb-4 text-xl">
                  <strong>7.4%</strong> absolute benefit in iDFS at 5 years
                  <sup>5</sup>
                </P>
                <P cyan tw="text-xl">
                  Descriptive analysis, not prespecified or powered.
                  <sup>5</sup>
                </P>
              </Div>
            }
            customCss={tw`lg:(p-8 items-center)`}
          />

          <Space xxxl />

          <Aside left>
            <DeepDiveButton direction="climb-out">
              View ExteNET iDFS data (no pCR)
            </DeepDiveButton>
          </Aside>

          <Footnotes
            footnotes={['absolute-benefit']}
            alignment="right"
            customCss={tw`mb-10`}
          />
        </DeepDiveSection>
      </DeepDive>
    </Layout>
  );
};

export default Page;
